import React from "react";
function HeroForCareerPage() {
  return (
    <>
      <section id="hero-for-career-page">
        <section>
          <div>
            <h1 className="career-quote">
              A Great Story has{" "}
              <span className="great-characters">Great Characters</span>
            </h1>
          </div>
          <div className="career-name">
            <p>- OKEDU KAMALU (MD)</p>
          </div>
        </section>
      </section>
    </>
  );
}

export default HeroForCareerPage;
