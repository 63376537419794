import React from "react";
import HeroForCareerPage from "../components/HeroForCareerPage";
import Navbar from "../components/Navbar";
import Globe from "../assets/img/bright-globe.png";
import Picture1 from "../assets/img/why-join-us-1.jpg";
// import Picture2 from "../assets/img/why-join-us-2.jpg";
import Picture3 from "../assets/img/why-join-us-3.jpeg";
import Mail from "../assets/img/mail-icon.png";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import { Fade } from "react-slideshow-image";

function Career() {
  const fadeImages = [Picture1, Picture3];
  const fadeProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
  };
  return (
    <>
      <header>
        <Navbar />
        <HeroForCareerPage />
      </header>
      <main>
        {/* Call To Action */}
        <section className="career-cta">
          <section className="career-cta-items">
            <img
              src={Globe}
              alt="globe"
              className="career-cta-globe img-fluid"
            />
            <div className="career-cta-container">
              <p className="career-cta-description">
                We set out to build a company that will be synonymous with
                consistent, best-in-class service. Our founding brief ‘meet
                requirements, exceed expectations’ guides our approach to every
                project we undertake.
              </p>
              <Link to="/about-us">
                <p className="career-about-us text-capitalize">
                  more about us <ArrowForward />
                </p>
              </Link>
            </div>
          </section>
        </section>
        {/* Why Join Us */}
        <section className="why-join-us-container">
          <div className="why-join-us">
            <div className="col-lg-5 why-join-us-img">
              <Fade {...fadeProperties}>
                <div className="each-fade">
                  <img
                    src={fadeImages[0]}
                    alt="about"
                    className="img-fluid career-img1"
                  />
                </div>
                {/* <div className="each-fade">
                <img
                  src={fadeImages[1]}
                  alt="about"
                  className="img-fluid career-img2"
                />
              </div> */}
                <div className="each-fade">
                  <img
                    src={fadeImages[1]}
                    alt="about"
                    className="img-fluid career-img3"
                  />
                </div>
              </Fade>
            </div>
            <div className="col-lg-7 why-join-us-description">
              <p className="text-center text-black">Why Join Us?</p>
              <p className="text-black">
                At Debomac, we have a corporate value system that celebrates
                diversity and rewards commitment. Our curriculum of onboarding
                protocols and training programmes help you reach your highest
                potentials and quickly become an invaluable part of our
                organisation. we recognize the importance of getting the right
                mix of human resource in creating a world class brand and so we
                are constantly on the lookout for great talent. If you have the
                personality and skill that we are looking for, come join our
                team of outstanding individuals and feature prominently in a
                great success story.
              </p>
              <Link to="/about-us">
                <p className="policy-statement">
                  Our Equal Opportunities Policy Statement <ArrowForward />
                </p>
              </Link>
            </div>
          </div>
        </section>
        {/* Open Job Vacancies */}
        <section className="open-vacancies-cta-container">
          <div className="open-vacancies-cta">
            <h1 className="text-black text-center mb-4">Open vacancies</h1>
            <p className="text-black text-center">
              If you are interested in any of our open job vacancies, click on
              <b> ‘Apply Now’</b> to browse through its requirements and apply
              for them if your experiences and skills are a qualified fit.
            </p>
          </div>
        </section>
        <section className="open-vacancies">
          <table className="table">
            <thead className="job-opening-header">
              <tr>
                <th>Ref</th>
                <th>Job Title</th>
                <th>Location</th>
                <th>Employment Type</th>
                <th>Closing Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="job-opening-body">
              <tr>
                <td data-label="Ref">E23</td>
                <td data-label="Job Title">Project Manager</td>
                <td data-label="Location">Lagos</td>
                <td data-label="Employment Type">Permanent</td>
                <td data-label="Closing Date">12-Feb-2022</td>
                <td>
                  <a href="/#" className="apply-now">
                    Apply Now
                  </a>
                </td>
              </tr>
              {/* <tr>
                <td data-label="Ref">E23</td>
                <td data-label="Job Title">Project Manager</td>
                <td data-label="Location">Lagos</td>
                <td data-label="Employment Type">Permanent</td>
                <td data-label="Closing Date">12-Feb-2022</td>
                <td>
                  <a href="/#" className="apply-now">
                    Apply Now
                  </a>
                </td>
              </tr> */}
            </tbody>
          </table>
        </section>

        <section id="help" className="help-header-alt">
          <div className="text-center mt-4" data-aos="fade-up">
            <h2 className="help-header">
              Want to find out how we can assist you?
            </h2>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container mt-4" data-aos="fade-up">
            <div className="row">
              <div className="contact-section">
                <div className="contact-item">
                  <h2 className="contact-header">Contact</h2>
                </div>
                <div className="contact-item">
                  <a href="mailto:info@debomac.ng">
                    <img src={Mail} alt="mail" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Career;
