import React from "react";
import Hero1 from "../assets/img/globe2.jpg";
import Mail from "../assets/img/mail-icon.png";
import Farmer from "../assets/img/farmer.png";
import Okedu from "../assets/img/Okedu.png";
import Chinaza from "../assets/img/Chinaza.png";
import Nnenna from "../assets/img/Nnenna.jpg";
import Tahomah from "../assets/img/Tahomah.jpg";
import PeopleIcon from "../assets/img/people-icon.png";
import OurValues from "../assets/img/our-values.png";
import OurServiceOverview from "../assets/img/our-service-overview.png";
import Vision from "../assets/img/vision.png";
import MissionStatement from "../assets/img/mission-statement-bg.png";
import WomanWithVision from "../assets/img/woman-with-vision.png";
import Globe from "../assets/img/globe.png";
import GreenBackground from "../assets/img/green-background.png";
import Overview1 from "../assets/img/our-service-overview-mobile-view-1.png";
import Overview2 from "../assets/img/our-service-overview-mobile-view-2.png";
import Overview3 from "../assets/img/our-service-overview-mobile-view-3.png";
import Overview4 from "../assets/img/our-service-overview-mobile-view-4.png";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

function VisionAndMission() {
  return (
    <>
      <Navbar />
      <header>
        <div className="globe-container position-relative">
          <img src={Hero1} alt="globe" className="hero-item" />
        </div>
        <div className="hero-card hero-card-globe card-two text-center position-absolute">
          <div className="mb-3">
            <h1 className="hero-header">
              We are a local company with <br />
              <span className="global">global</span> <br />
              Standards.
            </h1>
          </div>
          <div className="hero-border">
            <div className="circle"></div>
            <div className="hero-border-line"></div>
            <div className="circle"></div>
          </div>
          <div>
            <h1 className="hero-header">
              We are committed to delivering to our clients, complete functional
              solutions that stand the test of time
            </h1>
          </div>
          <div className="hero-button-for-services">
            <Link to="/our-services">
              <button>Our Services</button>
            </Link>
          </div>
        </div>
      </header>
      <main id="main">
        {/* About Us */}
        <section id="about-us">
          <div className="green-bg-container col-sm-12 col-md-12 col-lg-5">
            <div>
              <img
                src={GreenBackground}
                alt="green"
                className="green-background img-fluid"
              />
            </div>
            <div>
              <h2>
                ‘Whatever the size of your projects, our approach is similar,{" "}
                <br />
                <span className="about-action-header text-white">
                  <b>
                    {" "}
                    meet <br /> requirements, <br /> exceed <br /> expectations
                  </b>
                </span>
                ’.
              </h2>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-7">
            <div>
              <div className="about-us-header d-flex justify-content-center align-items-center flex-column mt-5">
                <img src={Globe} alt="globe" className="img-fluid" />
                <h1>About Us</h1>
                <div className="about-us-underline"></div>
              </div>
              <div className="about-us-description">
                <p>
                  We are a Business Support Service company organized to provide{" "}
                  <b>Project Management</b>, <b>Procurement</b>,{" "}
                  <b>Logistics and Business Advisory Services</b> to clients in
                  the Construction, IT, Energy, Consumer Goods, Financial
                  Services, Telecommunications, and Agricultural sectors in
                  Nigeria and beyond.
                </p>
                <p>
                  We work with our clients to provide tailored solutions that
                  address their needs whether it is a project requiring
                  masterful execution, supplies requiring quick sourcing,
                  storage or on-schedule relocation of personal, office and
                  industrial effects, or an organisation requiring process
                  review and re-engineering to shed inefficient administrative
                  and operational layers.
                </p>
                <p>
                  Whatever the size of our projects, our approach is similar,
                  meet requirements, exceed expectations.
                </p>
                <p>
                  We boast a team of young, committed professionals who have
                  distinguished themselves in specializations that constitute
                  our core service offerings. We join a vanguard of disruptors
                  who are challenging norms and breaking down geographical
                  barriers to place Nigeria on the map for excellent service
                  delivery.
                </p>
                <p>
                  With operational bases in Lagos, Malabo (Equatorial Guinea),
                  London (UK) and Houston, Texas (USA), we are strategically
                  placed to deliver to our clients best-in-class services
                  wherever they are needed.
                </p>
                <div className="about-contact-section">
                  <a href="/#contact">
                    <div>
                      <h2 className="about-contact-header">Contact Us</h2>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="vision">
          <div className="d-flex align-items-end justify-content-end">
            <div className="carousel-indicators d-none">
              <button
                type="button"
                data-bs-target="#aboutSection"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#aboutSection"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#aboutSection"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div
              id="aboutSection"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="section2">
                    <img
                      src={MissionStatement}
                      alt="mission statement"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="section1">
                    <img src={Vision} alt="vision" className="img-fluid" />
                    <img
                      src={WomanWithVision}
                      alt="woman with vision"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="section3">
                    <img src={Farmer} alt="Farmer" className="img-fluid" />
                    <img
                      src={OurValues}
                      alt="Our Values"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Mobile View */}
        <section id="our-service-overview-1">
          <div className="our-service-overview-item-1">
            <img
              src={Overview1}
              alt="our service overview"
              className="img-fluid"
            />
          </div>
          <div className="our-service-overview-item-2">
            <img
              src={Overview2}
              alt="our service overview"
              className="img-fluid"
            />
          </div>
          <div className="our-service-overview-item-3">
            <img
              src={Overview3}
              alt="our service overview"
              className="img-fluid"
            />
          </div>
          <div className="our-service-overview-item-4">
            <img
              src={Overview4}
              alt="our service overview"
              className="img-fluid"
            />
          </div>
        </section>
        {/* Desktop View */}
        <section id="our-service-overview-2">
          <img
            src={OurServiceOverview}
            alt="our service overview"
            className="img-fluid"
          />
        </section>
        {/* Core Team */}
        <section id="team">
          <div className="team-container">
            <h2 className="team-header">Our Core Team</h2>
            <div className="underline"></div>
            <p className="seasoned-achievers">
              we boast a management team of young, seasoned achievers and
              change-makers <br />
              <div className="text-center">
                <span className="push-boundaries">
                  who push the boundaries of what is possible
                </span>
              </div>
            </p>
          </div>
          <div className="our-core-team mt-5">
            <div className="our-core-team-profile-odd">
              <div className="our-core-team-image-odd d-flex justify-content-end">
                <div className="person-odd">
                  <h2>Okedu Kamalu</h2>
                  <p className="office-position">Founder/CEO</p>
                </div>
                <div>
                  <img
                    src={Okedu}
                    alt="Okedu"
                    className="img-fluid team-image-icon"
                  />
                </div>
              </div>
              <div className="team-description-odd">
                <p>
                  Okedu brings 10+ years of meritorious experience in Project
                  Management, Supply Chain & Distribution, Sales, Marketing,
                  Business Development and Strategy . He has successfully
                  implemented the Enterprise restructuring and midwifed Change
                  Management of businesses across a diverse industrial spectrum
                  ranging from Hospitality to Logistics, and Energy.
                </p>
              </div>
            </div>
            <div className="people-icon mt-4">
              <img src={PeopleIcon} alt="people" style={{ width: "60px" }} />
            </div>
            <div className="our-core-team-profile-even col-sm-12 col-md-12 col-lg-6">
              <div className="our-core-team-image-even">
                <img
                  src={Chinaza}
                  alt="Chinaza"
                  className="img-fluid team-image-icon"
                />
              </div>
              <div className="team-description-even">
                <div className="pb-3">
                  <h2>Chinaza Ojukwu</h2>
                  <p className="office-position">Engagement Manager</p>
                </div>
                <p>
                  Chinaza is a consummate personnel manager with proven
                  expertise in strategy development and impact analysis. She
                  leads the Business Compliance and Human Resource arms of our
                  organization.
                </p>
              </div>
            </div>
          </div>
          <div className="our-core-team mt-5">
            <div className="our-core-team-profile-odd">
              <div className="our-core-team-image-odd d-flex justify-content-end">
                <div className="person-odd">
                  <h2>Nnenna Wanorue</h2>
                  <p className="office-position">Supply Chain Manager</p>
                </div>
                <div>
                  <img
                    src={Nnenna}
                    alt="Nnenna"
                    className="img-fluid team-image-icon"
                  />
                </div>
              </div>
              <div className="team-description-odd">
                <p>
                  Cynthia has a proven track record of operating excellence in
                  her SCM career. She is the Subject Matter Expert in end to end
                  supply chain management and its related value chain
                  interactions; consistently delivering measurable &
                  quantifiable results in all her engagements.
                </p>
              </div>
            </div>
            <div className="people-icon mt-4">
              <img src={PeopleIcon} alt="people" style={{ width: "60px" }} />
            </div>
            <div className="our-core-team-profile-even col-sm-12 col-md-12 col-lg-6 d-flex">
              <div className="our-core-team-image-even">
                <img
                  src={Tahomah}
                  alt="Tahomah"
                  className="img-fluid team-image-icon"
                />
              </div>
              <div className="team-description-even">
                <div className="pb-3">
                  <h2>Tahomah Alkamu</h2>
                  <p className="office-position">Project Manager, Lead</p>
                </div>
                <p>
                  Tahomah is an accomplished Project Manager, and Quality
                  Control guru. He has successfully managed several high value
                  turnkey projects and brings a treasure trove of experience and
                  knowledge to the team.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="help" className="help-header-alt">
          <div className="text-center mt-4" data-aos="fade-up">
            <h2 className="help-header">
              Want to find out what we can do for you?
            </h2>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container mt-4" data-aos="fade-up">
            <div className="row">
              <div className="contact-section">
                <div className="contact-item">
                  <h2 className="contact-header">Contact</h2>
                </div>
                <div className="contact-item">
                  <a href="mailto:info@debomac.ng">
                    <img src={Mail} alt="mail" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default VisionAndMission;
