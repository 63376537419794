import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Mail from "../../assets/img/mail-icon.png";
import Navbar from "../../components/Navbar";
import Hero from "../../components/Hero";
import Tasks from "../../assets/img/tasks.jpeg";
import BarCharts from "../../assets/img/bar-charts.png";
import DebomacPentagon from "../../assets/img/debomac-pentagon.png";
import LightBulbIcon from "../../assets/img/light-bulb-icon.png";
import MoneyIcon from "../../assets/img/money-icon.png";
import PeopleIcon from "../../assets/img/people-hierarchy.png";
import PeopleGridIcon from "../../assets/img/people-grid.png";
import SpannerIcon from "../../assets/img/spanner-icon.png";
import PmCardOne from "../../assets/img/pm-card-1.png";
import PmCardTwo from "../../assets/img/pm-card-2.png";
import PmCardThree from "../../assets/img/pm-card-3.png";
import PmCardFour from "../../assets/img/pm-card-4.png";
function ProjectManagement() {
  return (
    <>
      <Navbar />
      <Hero />
      <main id="main">
        {/* Project Management Tasks */}
        <section id="tasks">
          <div className="tasks-container">
            <div>
              <img
                src={Tasks}
                alt="tasks"
                className="img-fluid"
                style={{ height: "444px" }}
              />
            </div>
            <div>
              <img src={BarCharts} alt="bar charts" className="pm-barchart" />
            </div>
          </div>
          <div className="tasks-description">
            <h2 className="text-black">Our Project Management Services</h2>
            <p className="text-black">
              We offer comprehensive management services for your personal,
              commercial , CSR or public construction projects. Whatever the
              size and stage of your project, we <b>work with you</b> to deliver{" "}
              turnkey or tailored solutions that <b>work for you</b>. Our
              cross-functional team of experts utilize several established
              project management frameworks like <em>Waterfall</em> and{" "}
              <em>Agile</em> to give you complete visibility and control of your
              projects at every stage.
            </p>
          </div>
        </section>

        {/* Approach to Project Management */}
        <section id="approach-to-pm">
          <div className="container">
            <div className="row">
              <div className="approach-to-pm-container">
                <div className="mb-2">
                  <h1>Our Approach to Project Management</h1>
                  <div className="underline-3"></div>
                </div>
                <div className="approach-to-pm-description">
                  <p>
                    We offer engagement services cascading from{" "}
                    <b>
                      {" "}
                      project scoping, budget advisory, team selection, risk
                      management, procurement, delivery services, project
                      supervision, project implementation, scheduled or
                      close-out analytics & documentation to project
                      commissioning event management and post-realization
                      support.
                    </b>{" "}
                    Our extensive supplier base and experience enable us deliver
                    to our clients, projects with expert fidelity to scope and
                    schedule projections,{" "}
                    <b className="possible-cost text-decoration-underline">
                      at the lowest possible cost.
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pm-pentagon-container">
            <h1 className="pm-pentagon-header">
              Debomac PM Engagement Pentagon
            </h1>
            <p className="pm-pentagon-description">
              We provide five (5) different project management services as
              stand-alone value added offerings to ensure that our clients get
              value for investment from the minute we are introduced to any
              stage of the project cycle.
            </p>
            <div className="pm-pentagon">
              <div className="debomac-pentagon">
                <img src={DebomacPentagon} alt="debomac pentagon" />
              </div>
              <div className="pm-pentagon-items">
                <div className="pm-pentagon-item">
                  <div className="pm-pentagon-icon">
                    <img src={LightBulbIcon} alt="icon" />
                  </div>
                  <div>
                    <p>
                      We work with our clients to breakdown skill, financial,
                      material, statutory, operational, administrative and
                      strategic requirements for a successful completion of the
                      project. We also manage the concept, schematic or
                      technical design process as well as supervise or manage
                      the shortlisting and onboarding of the implementation
                      cross-functional teams (CFT). This enables our client
                      properly appraise their project, eliminate deficit project
                      funding and ensure on-schedule and on-budget project
                      delivery.
                    </p>
                  </div>
                </div>
                <div className="pm-pentagon-item">
                  <div className="pm-pentagon-icon">
                    <img src={MoneyIcon} alt="icon" />
                  </div>
                  <div>
                    <p>
                      We leverage our cost control expertise and extensive
                      supplier network to provide sourcing services at
                      unbeatable rates and uncompromising quality standards. We
                      also provide logistics, installation, training or
                      scheduled-maintenance as part of our Comprehensive
                      Procurement & Supply Chain Management Service.
                    </p>
                  </div>
                </div>
                <div className="pm-pentagon-item">
                  <div className="pm-pentagon-icon">
                    <img src={PeopleIcon} alt="icon" />
                  </div>
                  <div>
                    <p>
                      We offer Project Inspection, Testing, Performance
                      Appraisal, Milestone Management, Risk Management, Quality
                      Assurance and Control, QHSE Management and Assessment as
                      part of our Comprehensive Project Supervision Service
                      Offering.
                    </p>
                  </div>
                </div>
                <div className="pm-pentagon-item">
                  <div className="pm-pentagon-icon">
                    <img src={PeopleGridIcon} alt="icon" />
                  </div>
                  <div>
                    <p>
                      We are in our elements when we are commissioned to fully
                      implement projects at its inception. We manage the entire
                      implementation value chain from pre-realization phase to
                      deliver a fully functional project complete with
                      documentation, statutory and quality certifications.
                    </p>
                  </div>
                </div>
                <div className="pm-pentagon-item">
                  <div className="pm-pentagon-icon">
                    <img src={SpannerIcon} alt="icon" />
                  </div>
                  <div>
                    <p>
                      We offer project maintenance and trouble-shooting training
                      as well as ‘Continuous Improvement’ engagement services as
                      part of our Project Maintenance value added service. We
                      also offer Project Management Office set-up or Management
                      services for clients.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Project Management Cards */}
        <section id="pm-cards">
          <div className="pm-card">
            <img src={PmCardOne} alt="pm card" className="img-fluid" />
          </div>
          <div className="pm-card">
            <img src={PmCardTwo} alt="pm card" className="img-fluid" />
          </div>
          <div className="pm-card">
            <img src={PmCardThree} alt="pm card" className="img-fluid" />
          </div>
          <div className="pm-card">
            <img src={PmCardFour} alt="pm card" className="img-fluid" />
          </div>
        </section>

        {/* Project Management CTA */}
        <section id="pm-cta">
          <div className="pm-cta-container">
            <h1 className="pm-cta-header">
              “Whatever the size and stage of your project, we{" "}
              <b>
                <u>work with you</u>
              </b>{" "}
              to deliver turnkey or tailored solutions that{" "}
              <b>
                <u>work for you</u>
              </b>
              .”
            </h1>
            <p className="text-white">
              To Learn more about other services we offer, kindly click on any
              of the links below
            </p>
            <p className="text-white">
              <b>Other Services We Offer</b>
            </p>
          </div>
          <div className="pm-cta-button">
            <Link to="/procurement">
              <p className="text-white">Procurement</p>
            </Link>
          </div>
        </section>

        {/* Help */}
        <section id="help">
          <div className="container text-center mt-4" data-aos="fade-up">
            <h2 className="help-header">
              Want to find out how we can assist you?
            </h2>
          </div>
        </section>

        {/* Contact */}
        <section id="contact" className="contact">
          <div className="container mt-4" data-aos="fade-up">
            <div className="row">
              <div className="contact-section">
                <div className="contact-item">
                  <h2 className="contact-header">Contact</h2>
                </div>
                <div className="contact-item">
                  <a href="mailto:info@debomac.ng">
                    <img src={Mail} alt="mail" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default ProjectManagement;
