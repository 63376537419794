import React from "react";
import Slider from "react-slick";
import Client1 from "../assets/partners/client1.png";
import Client2 from "../assets/partners/client2.png";
import Client3 from "../assets/partners/client3.png";
import Client4 from "../assets/partners/client4.png";
import Client5 from "../assets/partners/client5.png";
import Client6 from "../assets/partners/client6.png";
import Client7 from "../assets/partners/client7.png";

export default function SlickCarousel() {
  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      <div className="clients-container">
        <img src={Client1} alt="client" className="client-logo" />
      </div>
      <div className="clients-container">
        <img src={Client2} alt="client" className="client-logo" />
      </div>
      <div className="clients-container">
        <img src={Client3} alt="client" className="client-logo" />
      </div>
      <div className="clients-container">
        <img src={Client4} alt="client" className="client-logo" />
      </div>
      <div className="clients-container">
        <img src={Client5} alt="client" className="client-logo" />
      </div>
      <div className="clients-container">
        <img src={Client6} alt="client" className="client-logo-alt" />
      </div>
      <div className="clients-container">
        <img src={Client7} alt="client" className="client-logo-alt" />
      </div>
    </Slider>
  );
}
