import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import OurServices from "./pages/OurServices";
import AboutUs from "./pages/AboutUs";
import ProjectManagement from "./pages/subpages/ProjectManagement";
import Procurement from "./pages/subpages/Procurement";
import Logistics from "./pages/subpages/Logistics";
import Career from "./pages/Career";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route
            path="/our-services/project-management"
            element={<ProjectManagement />}
          />
          <Route path="/our-services/procurement" element={<Procurement />} />
          <Route path="/our-services/logistics" element={<Logistics />} />
          <Route path="/career" element={<Career />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
