import React from "react";
import { Nav } from "react-bootstrap";
import Twitter from "../assets/img/twitter.png";
import Instagram from "../assets/img/instagram.png";
import LinkedIn from "../assets/img/linkedin.png";
import { LinkContainer } from "react-router-bootstrap";
function Footer() {
  return (
    <>
      <footer id="footer">
        <div className="footer-top d-flex">
          <div className="col-lg-4 footer-links">
            <h4 className="footer-header">Quick Links</h4>
            <ul>
              <li>
                <LinkContainer to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to="/about-us">
                  <Nav.Link>About us</Nav.Link>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to="/about-us">
                  <Nav.Link>Our Management Team</Nav.Link>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to="/our-services">
                  <Nav.Link>Our Services</Nav.Link>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to="/our-services">
                  <Nav.Link>Our Quality Policy</Nav.Link>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to="/about-us">
                  <Nav.Link>Our Code of Ethics</Nav.Link>
                </LinkContainer>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 footer-links">
            <h1 className="text-uppercase">Follow Us</h1>
            <div className="social-icons">
              <a href="/#">
                <img
                  className="linkedin"
                  src={LinkedIn}
                  alt="linkedin"
                  style={{ width: "50px", height: "50px" }}
                />
              </a>
              <a href="/#">
                <img
                  className="twitter"
                  src={Twitter}
                  alt="twitter"
                  style={{ width: "60px", height: "50px" }}
                />
              </a>
              <a href="/#">
                <img
                  className="instagram"
                  src={Instagram}
                  alt="instagram"
                  style={{ width: "50px" }}
                />
              </a>
            </div>
          </div>

          <div className="col-lg-4 footer-links remove-border">
            <div>
              <h4 className="footer-header">Head Office</h4>
              <ul>
                <li>
                  <Nav.Link href="/#">
                    8 Oduduwa Way, Ikeja GRA, Lagos State, NIGERIA
                  </Nav.Link>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="footer-header">Phone & Email</h4>
              <ul>
                <li>
                  <Nav.Link href="mailto:info@debomac.ng">
                    info@debomac.ng
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/#">+234 903 738 0009</Nav.Link>
                </li>
                <li>
                  <Nav.Link href="/#">+234 803 767 6489</Nav.Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="copyright">
          Copyright &copy; 2022
          <span> by Debomac Global Services Limited. All rights Reserved.</span>
        </div>
      </footer>
    </>
  );
}

export default Footer;
