import React from "react";
import Globe from "../assets/img/globe.png";
import Hero1 from "../assets/img/meeting.jpg";
import Hero2 from "../assets/img/construction.jpg";
import Hero3 from "../assets/img/code-background.jpg";
import Hero4 from "../assets/img/conference-center.jpg";
import Hero5 from "../assets/img/new-data-services.jpg";
// import Hero6 from "../assets/img/mega-store.jpeg";

function Hero() {
  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container-fluid hero-container position-relative text-center text-lg-start">
          <div className="row">
            <div
              className="d-flex align-items-end justify-content-end"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="carousel-indicators d-none">
                <button
                  type="button"
                  data-bs-target="#heroSection"
                  data-bs-slide-to="4"
                  aria-label="Slide 5"
                ></button>
                <button
                  type="button"
                  data-bs-target="#heroSection"
                  data-bs-slide-to="5"
                  aria-label="Slide 6"
                ></button>
                <button
                  type="button"
                  data-bs-target="#heroSection"
                  data-bs-slide-to="6"
                  aria-label="Slide 7"
                ></button>
                <button
                  type="button"
                  data-bs-target="#heroSection"
                  data-bs-slide-to="8"
                  aria-label="Slide 9"
                ></button>
                <button
                  type="button"
                  data-bs-target="#heroSection"
                  data-bs-slide-to="9"
                  aria-label="Slide 10"
                ></button>
              </div>
              <div
                id="heroSection"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="globe-container position-relative hero-with-hand">
                      <img src={Hero1} alt="globe" className="hero-item" />
                    </div>
                    <div className="hero-card service-delivery-conatiner text-center position-absolute">
                      <img src={Globe} alt="globe" className="globe" />
                      <h1 className="service-delivery">
                        Service delivery with emphasis on <br />
                        <span className="excellence">excellence</span>
                      </h1>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="globe-container position-relative">
                      <img src={Hero2} alt="globe" className="hero-item" />
                    </div>
                    <div className="hero-card card-two text-center position-absolute">
                      <div className="mb-4 hero-text-container">
                        <h1 className="text-white text-uppercase construction">
                          Construction
                        </h1>
                        <div className="underline-white"></div>
                      </div>
                      <div className="service-items">
                        <h2>Design</h2>
                        <h2>Build</h2>
                        <h2>Equip</h2>
                        <h2>Maintain</h2>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="globe-container position-relative">
                      <img src={Hero3} alt="globe" className="hero-item" />
                    </div>
                    <div className="hero-card card-two text-center position-absolute">
                      <div className="mb-4 hero-text-container">
                        <h1 className="text-uppercase text-white project-management-header">
                          I.T PROJECT MANAGEMENT
                        </h1>
                        <div className="underline-white"></div>
                      </div>
                      <div className="service-items">
                        <h2>Plan</h2>
                        <h2>Execute</h2>
                        <h2>Monitor</h2>
                        <h2>Control</h2>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="globe-container position-relative">
                      <img src={Hero4} alt="globe" className="hero-item" />
                    </div>
                    <div className="hero-card card-two text-center position-absolute">
                      <div className="mb-4 hero-text-container">
                        <h1 className="text-uppercase text-white hero-text-container event-management">
                          EVENT MANAGEMENT
                        </h1>
                        <div className="underline-white"></div>
                      </div>
                      <div className="service-items">
                        <h2>Plan</h2>
                        <h2>Budget</h2>
                        <h2>Source</h2>
                        <h2>Manage</h2>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="globe-container position-relative">
                      <img src={Hero5} alt="globe" className="hero-item" />
                    </div>
                    <div className="hero-card card-two text-center position-absolute">
                      <div className="mb-4 hero-text-container">
                        <h1 className="text-uppercase text-white pmo-office">
                          PMO OFFICE SET-UP
                        </h1>
                        <div className="underline-white"></div>
                      </div>
                      <div className="service-items">
                        <h2>Recruit</h2>
                        <h2>Onboard</h2>
                        <h2>Outsource</h2>
                        <h2>Manage</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
