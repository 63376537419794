import { Fade } from "react-slideshow-image";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Service1 from "../../assets/img/delivery-truck-1.jpeg";
import Service2 from "../../assets/img/delivery-truck-2.jpeg";
import Service3 from "../../assets/img/delivery-truck-3.jpeg";
import Service4 from "../../assets/img/delivery-truck-4.jpeg";
import Service5 from "../../assets/img/our-logistics-motto.png";
import Mail from "../../assets/img/mail-icon.png";

function Logistics() {
  const fadeImages = [Service1, Service2, Service3];
  const fadeProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
  };
  return (
    <>
      <Navbar />
      <header>
        <div className="hero-for-logistics-md logistics-items">
          <Fade {...fadeProperties}>
            <div className="each-fade">
              <img
                src={fadeImages[0]}
                alt="service"
                className="img-fluid logistics-item"
              />
            </div>
            <div className="each-fade">
              <img
                src={fadeImages[1]}
                alt="service"
                className="img-fluid logistics-item"
              />
            </div>
            <div className="each-fade">
              <img
                src={fadeImages[2]}
                alt="service"
                className="img-fluid logistics-item"
              />
            </div>
          </Fade>
        </div>
        <section className="hero-for-logistics-lg">
          <img src={Service1} alt="logistics" className="img-fluid" />
          <img src={Service2} alt="logistics" className="img-fluid" />
          <img src={Service3} alt="logistics" className="img-fluid" />
        </section>
      </header>
      <main>
        <section className="parked-truck">
          <div className="truck">
            <img src={Service4} alt="logistics" className="img-fluid" />
          </div>
          <div className="logistics-header-container">
            <h1 className="logistics-header">Our Logistics Services</h1>
            <p>
              We provide <b> uniquely fast, efficient and affordable </b>{" "}
              comprehensive third party logistics and value-added supply chain
              services ranging from local and international relocation of
              personal effects, office equipment, FMCG raw or finished goods,
              industrial machinery and spare parts, as well as warehousing and
              order fulfilment. Leveraging our deep industry knowledge,
              strategic local spread and international partnerships, we provide
              optimized customer centric 3PL solutions that help businesses
              reduce overhead costs, outsource non-core activities and scale
              output. In the highly competitive world of commerce, you need a
              partner that understands the importance of creating the right
              brand experience for your customers. At Debomac, we do not only
              offer route-to market distribution for your business, our major
              product is remarkable customer service. <br /> <b>Contact us</b>{" "}
              now for a free quote and let us be the vehicle to drive your
              business’s transition into a more efficient and profitable future.
            </p>
          </div>
          <div>
            <img
              src={Service5}
              alt="our logistics motto"
              className="img-fluid"
            />
          </div>
        </section>

        {/* Help */}
        <section id="help">
          <div className="container text-center mt-4" data-aos="fade-up">
            <h2 className="help-header">
              Want to find out how we can assist you?
            </h2>
          </div>
        </section>
        {/* Contact */}
        <section id="contact" className="contact">
          <div className="container mt-4" data-aos="fade-up">
            <div className="row">
              <div className="contact-section">
                <div className="contact-item">
                  <h2 className="contact-header">Contact</h2>
                </div>
                <div className="contact-item">
                  <a href="mailto:info@debomac.ng">
                    <img src={Mail} alt="mail" className="img-fluid" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Logistics;
