import React from "react";
function HeroForServicePage() {
  return (
    <>
      <section id="hero-for-service-page"></section>
    </>
  );
}

export default HeroForServicePage;
