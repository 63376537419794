import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";
import DebomacLogo from "../assets/img/debomac-white-logo-transparent.png";
import { Menu } from "@mui/icons-material";
import { useState } from "react";

function NavBar() {
  const [menubar, setMenuBar] = useState(false);
  const showMenu = () => setMenuBar(!menubar);
  return (
    <header className="nav-container">
      <div className="menu-icons" onClick={showMenu}>
        <Menu className="menu-icon" />
      </div>
      <nav
        className={menubar ? "nav-item active" : "nav-item"}
        onClick={showMenu}
      >
        <div className="nav-logo">
          <Link to="/">
            <img
              src={DebomacLogo}
              alt="debomac logo"
              style={{ width: "90px", height: "50px" }}
              className
            />
          </Link>
        </div>
        <ul className="menu">
          <Link to="/">
            <li className="menu-item">Home</li>
          </Link>
          <Link to="/about-us">
            <li className="menu-item">About Us</li>
          </Link>
          <Link to="/our-services">
            <li className="menu-item">Our Services</li>
          </Link>
          <Link to="/career">
            <li className="menu-item">Careers</li>
          </Link>
          <a href="/#contact">
            <li className="menu-item">Contact Us</li>
          </a>
          <li className="menu-item px-4">
            <Search />
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default NavBar;
