import React from "react";
import { Fade } from "react-slideshow-image";
import Footer from "../components/Footer";
import Picture1 from "../assets/img/picture1.png";
import Picture2 from "../assets/img/picture2.jpg";
import Picture3 from "../assets/img/picture3.jpg";
import Picture4 from "../assets/img/picture4.jpg";
import Testimonial1 from "../assets/img/testimonial1.png";
import Testimonial2 from "../assets/img/testimonial2.png";
import Testimonial3 from "../assets/img/testimonial3.png";
import Testimonial4 from "../assets/img/testimonial4.png";
import Globe from "../assets/img/globe2.png";
import Mail from "../assets/img/mail-icon.png";
import Partner1 from "../assets/partners/image1.png";
import Partner2 from "../assets/partners/image2.png";
import Partner3 from "../assets/partners/image3.png";
import Partner4 from "../assets/partners/image4.png";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Covid19 from "../assets/partners/covid19.jpeg";
import SlickCarousel from "../components/SlickCarousel";
import { Link } from "react-router-dom";
function Home() {
  const fadeImages = [Picture1, Picture2, Picture3, Picture4];
  const fadeProperties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
  };
  return (
    <>
      <Navbar />
      <Hero />
      <main id="main">
        {/* Debomac Profile */}
        <section id="about-debomac">
          <div className="debomac-profile">
            <div className="col-lg-8 debomac">
              <div className="debomac-container">
                <div className="debomac-header-container">
                  <h1 className="text-uppercase debomac-header">Debomac</h1>
                  <h2 className="text-uppercase debomac-header-description">
                    Global Services Limited
                  </h2>
                </div>
                <p className="debomac-description">
                  We are a Business Support Service Company organized to provide
                  Project Management, Procurement, Logistics and Business
                  Advisory Services to clients in the construction, IT, Energy,
                  Consumer Goods, Financial Services, Telecommunications, and
                  Agricultural sectors in Nigeria and beyond.
                </p>
              </div>
              <div>
                <Link to="/our-services">
                  <button className="btn text-uppercase">Find out more</button>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 debomac-images">
              <Fade {...fadeProperties}>
                <div className="each-fade">
                  <img
                    src={fadeImages[0]}
                    alt="about"
                    className="img-fluid debomac-img"
                  />
                </div>
                <div className="each-fade">
                  <img
                    src={fadeImages[1]}
                    alt="about"
                    className="img-fluid debomac-img"
                  />
                </div>
                <div className="each-fade">
                  <img
                    src={fadeImages[2]}
                    alt="about"
                    className="img-fluid debomac-img"
                  />
                </div>
                <div className="each-fade">
                  <img
                    src={fadeImages[3]}
                    alt="about"
                    className="img-fluid debomac-img"
                  />
                </div>
              </Fade>
            </div>
          </div>

          <div className="partners">
            <div>
              <h1 className="affiliations">Affiliations</h1>
            </div>
            <div className="partners-images">
              <img src={Partner1} alt="partner" className="partners-logo" />
              <img
                src={Partner2}
                alt="partner"
                style={{ width: "220px" }}
                className="partners-logo"
              />
              <img src={Partner3} alt="partner" className="partners-logo" />
              <img
                src={Partner4}
                alt="partner"
                style={{ width: "220px" }}
                className="partners-logo"
              />
            </div>
          </div>
        </section>
        {/* Partnership */}
        <section id="partnerships">
          <div className="container-fluid" data-aos="fade-up">
            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="partnerships position-relative">
                <div className="partnerships-item1 col-lg-4">
                  <img
                    src={Globe}
                    alt="globe"
                    className="partnerships-img img-fluid"
                  />
                </div>
                <div className="partnerships-item2 col-lg-8">
                  <div className="d-flex align-items-center flex-column">
                    <h1 className="partnerships-header">Partnerships</h1>
                    <div className="underline-green1"></div>
                  </div>
                  <div className="partnerships-descritpion-container">
                    <p className="partnerships-descritpion">
                      We leverage formidable strategic business partnerships
                      both locally and beyond Nigeria to deliver to our clients
                      diverse service offerings geared to ensure satisfactory
                      project execution and return on investment (ROI).
                    </p>
                  </div>
                  <div className="d-flex align-items-center flex-column">
                    <h1 className="partnerships-header partnerships-header2">
                      Our Clients
                    </h1>
                    <div className="underline-green2"></div>
                  </div>
                </div>
              </div>
              <div className="client-logos position-absolute">
                <SlickCarousel />
              </div>
            </div>
          </div>
        </section>
        {/* Testimonials */}
        <section id="testimonials">
          <div className="container" data-aos="fade-up">
            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="testimonial-container">
                <div className="testimonial-header-container">
                  <h1 className="testimonial-header">Testimonials</h1>
                  <div className="underline-green3"></div>
                </div>
                <div className="testimonial-items">
                  <div className="testimonial-item">
                    <div>
                      <img
                        src={Testimonial1}
                        alt="truck"
                        className="img-fluid"
                      />
                    </div>
                    <div>
                      <img
                        src={Testimonial2}
                        alt="testimonial"
                        className="img-fluid testimonial-item-description"
                        style={{ width: "532px" }}
                      />
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div>
                      <img
                        src={Testimonial3}
                        alt="steel rod"
                        className="img-fluid"
                      />
                    </div>
                    <div>
                      <img
                        src={Testimonial4}
                        alt="testimonial"
                        className="img-fluid testimonial-item-description"
                        style={{ width: "533px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Covid19 */}
        <section id="covid19">
          <div className="container" data-aos="fade-up">
            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="covid19">
                <div className="container1">
                  <img
                    src={Covid19}
                    alt="covid19"
                    className="img-fluid"
                    style={{ width: "980px", height: "auto" }}
                  />
                </div>
                <div className="container2">
                  <h2 className="text-capitalize covid19-header">
                    Our Covid-19 Response
                  </h2>
                  <p>
                    At Debomac, we are committed to providing a safe environment
                    for our employees and their families. With guidance from
                    Nigeria Center for Disease Control (NCDC), we have
                    incorporated into our Business Continuity Plan (BCP), a
                    robust COVID-19 Response Program which is fully activated
                    and constantly monitored at all levels of our organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="help">
          <div className="container text-center mt-4" data-aos="fade-up">
            <h2 className="help-header">
              Want to find out how we can assist you?
            </h2>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container mt-4" data-aos="fade-up">
            <div className="row">
              <div className="contact-section">
                <div className="contact-item">
                  <h2 className="contact-header">Contact</h2>
                </div>
                <div className="contact-item">
                  <a href="mailto:info@debomac.ng">
                    <img src={Mail} alt="mail" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Home;
