import React from "react";
import Footer from "../../components/Footer";
import Mail from "../../assets/img/mail-icon.png";
import Store from "../../assets/img/store.jpeg";
import Truck from "../../assets/img/truck.jpeg";
import Scientist from "../../assets/img/scientist.jpeg";
import ConicalFlask from "../../assets/img/conical-flasks.jpeg";
import Crane from "../../assets/img/crane.jpeg";
import PackageDelivery from "../../assets/img/delivery.jpeg";
import Factory from "../../assets/img/factory.jpeg";
import MedicalBed from "../../assets/img/medical-bed.jpeg";
import Microscope from "../../assets/img/microscope.jpeg";
import OfficeDesk from "../../assets/img/office-desk.jpeg";
import Landline from "../../assets/img/telephone.jpeg";
import Gallery from "../../assets/img/gallery.png";
import Welder from "../../assets/img/welder.png";
import Conduct1 from "../../assets/img/conduct-1.png";
import Conduct2 from "../../assets/img/conduct-2.png";
import Conduct3 from "../../assets/img/conduct-3.png";
import Conduct4 from "../../assets/img/conduct-4.png";
import Conduct5 from "../../assets/img/conduct-5.png";
import Conduct6 from "../../assets/img/conduct-6.png";
import Conduct7 from "../../assets/img/conduct-7.png";
import Conduct8 from "../../assets/img/conduct-8.png";
import ShipContainer from "../../assets/img/container.jpeg";
import Navbar from "../../components/Navbar";
import Hero from "../../components/Hero";
function Procurement() {
  return (
    <>
      <Navbar />
      <Hero />
      <main id="main">
        <section id="procurement">
          <div className="procurement-images">
            <div>
              <img
                src={Store}
                alt="procurement"
                className="img-fluid procurement-image-1"
              />
            </div>
            <div>
              <img
                src={Truck}
                alt="procurement"
                className="img-fluid procurement-image-2"
              />
            </div>
            <div>
              <img
                src={ShipContainer}
                alt="procurement"
                className="img-fluid procurement-image-3"
              />
            </div>
          </div>
          <div className="procurement-description-container">
            <h1 className="procurement-header">Our Procurement Services</h1>
            <p className="procurement-description">
              Debomac Global Services Limited offers professional procurement
              services to individual, public and private sector clients for a
              wide array of products including but not limited to office &
              industrial equipment, office or personal furniture & fittings,
              mechanical, electrical and electronic equipment, personal
              protection gear, MRO spare parts as well as FMCG raw materials &
              finished goods.
            </p>
            <p className="procurement-description">
              We also offer value-added site delivery, installation, maintenance
              training or retainership support solutions to ensure that our
              clients enjoy sustainable total cost of ownership (TCO) on their
              investments.
            </p>
            <p className="procurement-description">
              We leverage a team of highly experienced procurement
              professionals, extensive original equipment manufacturer (OEM)
              partnerships and dynamic market intelligence to deploy tailored
              solutions that deliver on your sourcing objectives.
            </p>
            <div className="procurement-cta">
              <a href="/#contact">
                <div>
                  <h2 className="procurement-cta-header text-uppercase">
                    Contact Us
                  </h2>
                </div>
              </a>
            </div>
          </div>
        </section>
        {/* Large Screens */}
        <section className="gallery-lg">
          <img src={Gallery} alt="gallery" className="img-fluid" />
        </section>
        {/* Smaller Screens */}
        <section className="gallery-sm">
          <img
            src={ConicalFlask}
            alt="conical flasks"
            className="img-fluid item1"
          />
          <img src={Microscope} alt="microscope" className="img-fluid item2" />
          <img
            src={MedicalBed}
            alt="medical bed"
            className="img-fluid item3"
            // style={{ height: "356px" }}
          />
          <img src={Scientist} alt="scientist" className="img-fluid item4" />
          <img
            src={Welder}
            alt="welder"
            className="img-fluid item5"
            style={{ height: "348px" }}
          />
          <img
            src={Factory}
            alt="factory"
            className="img-fluid item6"
            style={{ height: "348px" }}
          />
          <img
            src={Crane}
            alt="crane"
            className="img-fluid item7"
            style={{ height: "348px" }}
          />
          <img
            src={PackageDelivery}
            alt="package delivery"
            className="img-fluid item8"
            style={{ height: "348px" }}
          />
          <img
            src={OfficeDesk}
            alt="office desk"
            className="img-fluid item9"
            style={{ height: "348px" }}
          />
          <img
            src={Landline}
            alt="telephone"
            className="img-fluid item10"
            style={{ height: "348px" }}
          />
        </section>
        <section className="conduct">
          <div className="conduct-header">
            <h1>
              Our 4-Point Procurement <em>Code of Conduct</em>
            </h1>
          </div>
          <div className="conduct-items">
            <img src={Conduct1} alt="code of conduct" className="img-fluid" />
            <img
              src={Conduct2}
              alt="code of conduct"
              className="img-fluid"
              style={{ width: "730px" }}
            />
          </div>
          <div className="conduct-items">
            <img src={Conduct3} alt="code of conduct" className="img-fluid" />
            <img
              src={Conduct4}
              alt="code of conduct"
              className="img-fluid"
              style={{ width: "730px" }}
            />
          </div>
          <div className="conduct-items">
            <img src={Conduct5} alt="code of conduct" className="img-fluid" />
            <img
              src={Conduct6}
              alt="code of conduct"
              className="img-fluid"
              style={{ width: "730px" }}
            />
          </div>
          <div className="conduct-items">
            <img src={Conduct7} alt="code of conduct" className="img-fluid" />
            <img
              src={Conduct8}
              alt="code of conduct"
              className="img-fluid"
              style={{ width: "730px" }}
            />
          </div>
        </section>
        <section id="help">
          <div className="container text-center mt-4" data-aos="fade-up">
            <h2 className="help-header">
              Want to find out how we can assist you?
            </h2>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container mt-4" data-aos="fade-up">
            <div className="row">
              <div className="contact-section">
                <div className="contact-item">
                  <h2 className="contact-header">Contact</h2>
                </div>
                <div className="contact-item">
                  <a href="mailto:info@debomac.ng">
                    <img src={Mail} alt="mail" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Procurement;
