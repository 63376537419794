import React from "react";
import Footer from "../components/Footer";
import Mail from "../assets/img/mail-icon.png";
import Navbar from "../components/Navbar";
import HeroForServicePage from "../components/HeroForServicePage";
import Icon1 from "../assets/img/icon1.png";
import Icon2 from "../assets/img/icon2.png";
import Icon3 from "../assets/img/icon3.png";
import Icon5 from "../assets/img/icon5.png";
import Projects from "../assets/img/recent-projects.png";
import Project1 from "../assets/img/recent-project-1.png";
import Project2 from "../assets/img/recent-project-2.png";
import Project3 from "../assets/img/recent-project-3.png";
import Handshake from "../assets/img/handshake.jpg";
import Globe from "../assets/img/globe.png";
import { Link } from "react-router-dom";

function OurServices() {
  return (
    <>
      <Navbar />
      <HeroForServicePage />
      <main id="main">
        {/* Service Cards */}
        <section id="service-cards">
          <div className="service-cards-container d-flex">
            <div className="project-management-card text-center">
              <Link to="/our-services/project-management">
                <h2 className="service-header text-black">
                  Project Management
                </h2>
                <img src={Icon1} alt="icon" className="service-card-icon-one" />
              </Link>
            </div>
            <div className="procurement-card text-center">
              <Link to="/our-services/procurement">
                <h2 className="service-header">Procurement</h2>
                <img src={Icon2} alt="icon" className="service-card-icon-two" />
              </Link>
            </div>
            <div className="logistic-card text-center">
              <Link to="/our-services/logistics">
                <h2 className="service-header text-black">Logistics</h2>
                <img
                  src={Icon5}
                  alt="icon"
                  className="service-card-icon-three"
                />
              </Link>
            </div>
          </div>
        </section>
        {/* Other Information */}
        <section id="service-information">
          <div className="handshake">
            <img src={Handshake} alt="handshake" className="img-fluid" />
          </div>
          <div className="handshake-details">
            <img src={Globe} alt="globe" />
            <p>
              We offer a range of complementary business solutions that enable
              our clients concentrate on their core competences and support
              their transformation to a leaner, more profitable organisation.
            </p>
          </div>
        </section>
        {/* Services */}
        <section id="our-services">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="service-container d-flex align-items-center flex-column">
                <h1 className="what-we-do text-capitalize">What We Do</h1>
                <div className="services">
                  <div className="service">
                    <div className="service-item">
                      <div className="d-flex mb-5 flex-column justify-content-center align-items-center">
                        <img
                          src={Icon1}
                          alt="icon one"
                          style={{ width: "50px" }}
                        />
                        <div className="bottom-line"></div>
                        <h2>Project Management</h2>
                      </div>
                      <p>
                        We offer both turnkey and customized project management
                        services for clients who are particular about quality
                        and cost effectiveness. We leverage key industry
                        partnerships and extensive contract management
                        experiences to deliver world class projects that stand
                        the test of time.
                        <p>Our PM services include;</p>
                      </p>
                      <ul>
                        <li>Project Management Consulting (General),</li>
                        <li>Construction Project Management,</li>
                        <li>Office Effects Warehousing & Relocation,</li>
                        <li>IT Project Management,</li>
                        <li>Events Project Management,</li>
                        <li>
                          Project Management Office Set-up & process management
                          Training.
                        </li>
                      </ul>
                    </div>
                    <div className="text-center first-button">
                      <Link to="/our-services/project-management">
                        <button className="btn text-uppercase">
                          Find out more
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="service">
                    <div className="d-flex mb-5 flex-column justify-content-center align-items-center">
                      <img
                        src={Icon2}
                        alt="icon one"
                        style={{ width: "70px" }}
                        className="mb-2"
                      />
                      <div className="bottom-line"></div>
                      <h2>Procurement</h2>
                    </div>
                    <p>
                      We offer professional buyer services to savvy clients who
                      want to outsource the hassles of procurement to enable
                      them focus on their core operational competences and
                      functions. We leverage our extensive vendor database and
                      deploy global procurement teams and infrastructure to
                      evaluate and engage product suppliers with a view to
                      delivering the optimal Total Cost of Ownership (TCO) terms
                      for our clients. We specialize in the procurement of a
                      wide range of products and materials including;
                    </p>
                    <ul>
                      <li>Office Equipment, Office Furniture & Fittings,</li>
                      <li>Industrial Equipment,</li>
                      <li>Business Process Re-organisation,</li>
                      <li>FMCG Raw Materials,</li>
                      <li>MRO Spares,</li>
                      <li>General Merchandising.</li>
                    </ul>
                    <div className="text-center second-button">
                      <Link to="/our-services/procurement">
                        <button className="btn text-uppercase">
                          Find out more
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="service">
                    <div className="d-flex mb-5 flex-column justify-content-center align-items-center">
                      <img
                        src={Icon3}
                        alt="icon one"
                        style={{ width: "55px" }}
                        className="mb-2"
                      />
                      <div className="bottom-line"></div>
                      <h2>Logistics</h2>
                    </div>
                    <p>
                      Our robust distribution partnerships and systems guarantee
                      safe and competent end-to-end or tailored logistics supply
                      chain solutions to individual and corporate clients at
                      unbeatable rates. Our Logistics solutions include but are
                      not limited to;
                    </p>
                    <ul>
                      <li>Pharmaceutics Relocation,</li>
                      <li>Personal Effects Warehousing & Relocation, </li>
                      <li>Office Effects Warehousing & Relocation,</li>
                      <li>Artworks Warehousing & Relocation,</li>
                      <li> Construction Equipment Haulage,</li>
                      <li>Industrial Machinery & Equipment Haulage, </li>
                      <li>Inventory & Warehouse Management,</li>
                      <li>Comprehensive Supply Chain Management.</li>
                    </ul>
                    <div className="text-center third-button">
                      <Link to="/our-services/logistics">
                        <button className="btn text-uppercase">
                          Find out more
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Recent Projects */}
        {/* Large screens */}
        <section id="recent-projects-lg">
          <img src={Projects} alt="recent projects" className="img-fluid" />
        </section>

        {/* Smaller Screens */}
        <section id="recent-projects-sm">
          <h1 className="text-black m-4 recent-projects-header">
            Recent Projects
          </h1>
          <img src={Project1} alt="recent projects" className="img-fluid" />
          <img
            src={Project2}
            alt="recent projects"
            className="img-fluid mt-5"
          />
          <img src={Project3} alt="recent projects" className="img-fluid" />
        </section>

        <section id="help">
          <div className="container text-center mt-4" data-aos="fade-up">
            <h2 className="help-header">
              Want to find out how we can assist you?
            </h2>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container mt-4" data-aos="fade-up">
            <div className="row">
              <div className="contact-section">
                <div className="contact-item">
                  <h2 className="contact-header">Contact</h2>
                </div>
                <div className="contact-item">
                  <a href="mailto:info@debomac.ng">
                    <img src={Mail} alt="mail" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default OurServices;
